// Component states
import states from './border-modifier.state.js'
import SliderModifier from '../slider-modifier/Slider-modifier.vue'

// Components
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'

// Libraries
import {
  get as _get,
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Actions-mj-image'

// Properties
const props = {
  mjml: Object,
  conf: Object
}

// Data
const data = function () {
  return {
    states: _cloneDeep(states)
  }
}

// Components
const components = {
  SliderModifier,
  custColorPicker
}

// Methods
const methods = {

  // Func@resetBorder
  /**
   * On switch off, reset borders to border top value
   * @param  {Boolean} active
   */
  resetBorder () {
    if (this.states.extendedBordersOptions) {
      this.mjml.attributes['border-top'] = this.mjml.attributes['border']
      this.mjml.attributes['border-right'] = this.mjml.attributes['border']
      this.mjml.attributes['border-bottom'] = this.mjml.attributes['border']
      this.mjml.attributes['border-left'] = this.mjml.attributes['border']
      this.mjml.attributes.border = ''
    } else {
      this.mjml.attributes.border = this.mjml.attributes['border-top']
      this.mjml.attributes['border-top'] = ''
      this.mjml.attributes['border-right'] = ''
      this.mjml.attributes['border-bottom'] = ''
      this.mjml.attributes['border-left'] = ''
    }
  },
  // Func@resetBorder

  // Func@getValue
  /**
   * Get value of the border defined in property
   * @param  {String} key      (attribute: 'border', 'border-top',...)
   * @param  {String} property (property: 'size', 'type', 'color')
   *
   * @return {String}          (value of the property)
   */
  getValue (key, property) {
    let indexProperty;
    switch (property) {
      case 'size':
        indexProperty = 0
        break
      case 'type':
        indexProperty = 1
        break
      case 'color':
        indexProperty = 2
        break
    }

    if (this.states.extendedBordersOptions && indexProperty !== 0) {
      return this.mjml.attributes['border-top'].split(' ')[indexProperty]
    }
    if (this.mjml.attributes[key]) {
      return this.mjml.attributes[key].split(' ')[indexProperty]
    }
    return null
  },
  // Func@getValue

  // Func@onBorderSizeChanged
  /**
   * Callback called when the value of the `border size` controls changed
   * @param {Number} newValue
   */
  onBorderSizeChanged (newValue, key) {
    this.mjml.attributes[key] = `${newValue}px ${this.getValue(key, 'type') || 'solid'} ${this.getValue(key, 'color') || '#000000'}`
  },
  // Func@onBorderSizeChanged

  // Func@onBorderTypeChanged
  /**
   * Callback called when the value of the `border type` controls changed
   * @param {Number} newValue
   */
  onBorderTypeChanged (newValue, key) {
    if (this.states.extendedBordersOptions && key === 'border') {
      this.onBorderTypeChanged(newValue, 'border-top')
      this.onBorderTypeChanged(newValue, 'border-bottom')
      this.onBorderTypeChanged(newValue, 'border-left')
      this.onBorderTypeChanged(newValue, 'border-right')
    } else {
      this.mjml.attributes[key] = `${this.getValue(key, 'size')} ${newValue} ${this.getValue(key, 'color')}`
    }
    
  },
  // Func@onBorderTypeChanged

  // Func@onColorChanged
  /**
   * Callback called when the value of the `border color` controls changed
   * @param {Number} newValue
   */
  onColorChanged (newValue, key) {
    if (this.states.extendedBordersOptions && key === 'border') {
      this.onColorChanged(newValue, 'border-top')
      this.onColorChanged(newValue, 'border-bottom')
      this.onColorChanged(newValue, 'border-left')
      this.onColorChanged(newValue, 'border-right')
    } else {
      this.mjml.attributes[key] = `${this.getValue(key, 'size')} ${this.getValue(key, 'type')} ${newValue || ''}`
    }
  },
  // Func@onColorChanged

  // Func@onCornerRadiusChanged
  /**
   * Callback called when the value of the `corner radius` controls changed
   * @param {Number} newValue
   */
  onCornerRadiusChanged (newValue) {
    this.mjml.attributes['border-radius'] = `${newValue}px`
  },
  // Func@onCornerRadiusChanged

  // Func@paddingCheck
  /**
   * Init extendedBorder state; check if extension has been disabled
   */
  hasBorder(side) {
    const attr = this.mjml.attributes
    const border = attr[side]
    return !!border && border.split(' ')[0] !== '0px'
  },

  borderCheck () {
    const hasSideBorder = this.hasBorder('border-right') || this.hasBorder('border-left') ||  this.hasBorder('border-top') ||  this.hasBorder('border-bottom')
    this.states.extendedBordersOptions = hasSideBorder
  }
  // Func@paddingCheck
}

// Computed Methods
const computed = {

  // Func@radius
  /**
   * Border radius
   * @return {Number}
   */
  radius () {
    const disableRadius = _get(this.conf, 'disableRadius', false)
    return disableRadius ? null : parseInt(this.mjml.attributes['border-radius'])
  },
  // Func@radius

  // Func@enableExpendedMode
  /**
   * [enableExpendedMode description]
   * @return {[type]} [description]
   */
  enableExtendedMode () {
    return !_get(this.conf, 'disableExtendedBorders') && this.states.extendedBordersOptions
  }
  // Func@enableExpendedMode

}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components,
  created: methods.borderCheck
}
